import Link from "next/link";

import { Box, Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";

import { FaCoins } from "@react-icons/all-files/fa/FaCoins";

import { useUserInfo } from "shared/api/client/hook";

import { FreeCoinPortal } from "./free-coin-portal";

export const UserBalance = () => {
  const { data } = useUserInfo();

  const cost = data?.balance ?? 0;

  if (data)
    return (
      <Link passHref href="/account/balance">
        <Box as="a" target="_blank" rel="noopener noreferrer">
          <FreeCoinPortal>
            <Box>
              <Tag
                colorScheme="blue"
                borderRadius="ui-base"
                fontSize="lg"
                fontWeight={600}
                cursor="pointer"
                p="10px 12px"
              >
                <TagLeftIcon as={FaCoins} boxSize="12px" />
                <TagLabel>{cost}</TagLabel>
              </Tag>
            </Box>
          </FreeCoinPortal>
        </Box>
      </Link>
    );

  return null;
};
