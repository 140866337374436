import Link from "next/link";

import { useStore } from "effector-react/scope";

import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { useColorMode } from "@chakra-ui/color-mode";
import { Box, Stack, Text } from "@chakra-ui/layout";
import {
  Avatar,
  AvatarProps,
  Button,
  Link as ChakraLink,
  MenuItem,
} from "@chakra-ui/react";

import { FiExternalLink } from "@react-icons/all-files/fi/FiExternalLink";
import { FiLogOut } from "@react-icons/all-files/fi/FiLogOut";
import { FiMap } from "@react-icons/all-files/fi/FiMap";
import { FiSettings } from "@react-icons/all-files/fi/FiSettings";
import { SiReadthedocs } from "@react-icons/all-files/si/SiReadthedocs";

import { KeycloakUser, sessionModel } from "models/keycloak";
import { clientCookies } from "models/keycloak/cookies";

import { md5 } from "pure-md5";

const disableHoverEvents = {
  _focus: { backgroundColor: "unset" },
  _active: { backgroundColor: "unset" },
  _hover: { backgroundColor: "unset" },
};

interface UserAvatarProps extends AvatarProps {
  user: KeycloakUser;
}
export function UserAvatar({ user, ...props }: UserAvatarProps) {
  if (!user) return null;

  return (
    <Avatar
      {...props}
      name={(!user.picture && user.name) || undefined}
      src={
        user.picture ||
        `https://www.gravatar.com/avatar/${md5(user.email)}?d=404`
      }
    />
  );
}

export function UserMenuItem({ user }: { user: KeycloakUser }) {
  const { colorMode } = useColorMode();

  const emailColor = { light: "gray.800", dark: "gray.200" };

  return (
    <MenuItem p="4" as="div" minH="52px" rounded="4px" pointerEvents="none">
      <Box mr="3">
        <UserAvatar w="2.75rem" h="2.75rem" user={user} />
      </Box>
      <Stack overflow="hidden">
        <Text
          fontSize="md"
          fontWeight="800"
          color={emailColor[colorMode]}
          isTruncated
        >
          {user?.name || "user.name"}
        </Text>
        <Text fontSize="14px" color={emailColor[colorMode]} isTruncated>
          {user?.email ?? "user.email"}
        </Text>
      </Stack>
    </MenuItem>
  );
}

export function LogoutMenuItem() {
  const kc = useStore(sessionModel.$keycloak);
  const handleLogout = () => {
    if (kc) {
      kc.logout();
      clientCookies.clear();
    } else {
      console.error("No keycloak instance found.");
    }
  };

  const { colorMode } = useColorMode();

  const logOutBg = { light: "blue.900", dark: "white" };

  return (
    <MenuItem p="1" rounded="4px">
      <Button
        as="div"
        w="full"
        variant="ghost"
        aria-label="settings"
        justifyContent="flex-start"
        {...disableHoverEvents}
        color={logOutBg[colorMode]}
        fontWeight="medium"
        leftIcon={<FiLogOut />}
        onClick={handleLogout}
      >
        <Trans id="user-menu-logout">Logout</Trans>
      </Button>
    </MenuItem>
  );
}

export function SettingsMenuItem() {
  return (
    <Link href="/account/settings" passHref>
      <MenuItem p="1" rounded="4px" as="a">
        <Button
          as="div"
          w="full"
          variant="ghost"
          aria-label="settings"
          justifyContent="flex-start"
          {...disableHoverEvents}
          color="blue.400"
          fontWeight="medium"
          leftIcon={<FiSettings />}
        >
          <Trans>Settings</Trans>
        </Button>
      </MenuItem>
    </Link>
  );
}

export const DocsLinkMenuItem: React.FC = () => {
  const { i18n } = useLingui();

  const href =
    i18n.locale === "ru"
      ? "https://ru.docs.mapflow.ai"
      : "https://docs.mapflow.ai";

  return (
    <ChakraLink href={href} _hover={{}} isExternal>
      <MenuItem p="1" rounded="4px" as="div">
        <Button
          w="full"
          variant="ghost"
          aria-label="settings"
          justifyContent="flex-start"
          {...disableHoverEvents}
          color="blue.400"
          fontWeight="medium"
          leftIcon={<SiReadthedocs />}
          rightIcon={<FiExternalLink />}
        >
          <Trans>Documentation</Trans>
        </Button>
      </MenuItem>
    </ChakraLink>
  );
};

export function MapflowLinkMenuItem() {
  const { i18n } = useLingui();

  const href =
    i18n.locale === "ru" ? "https://mapflow.ai/ru" : "https://mapflow.ai";

  return (
    <ChakraLink href={href} _hover={{}} isExternal>
      <MenuItem p="1" rounded="4px" as="div">
        <Button
          w="full"
          variant="ghost"
          aria-label="settings"
          justifyContent="flex-start"
          {...disableHoverEvents}
          color="blue.400"
          fontWeight="medium"
          leftIcon={<FiMap />}
          rightIcon={<FiExternalLink />}
        >
          mapflow.ai
        </Button>
      </MenuItem>
    </ChakraLink>
  );
}
