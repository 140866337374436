import { AxiosError } from "axios";
import { useQuery, UseQueryOptions } from "react-query";
import { useEvent } from "effector-react/scope";

import wizardModel from "models/wizard";

import { Schema, UserInfo } from "types";

import { fetchSchema, fetchTileURLFromCOG, fetchUserInfo } from ".";

export const useUserInfo = () => {
  return useQuery<UserInfo, AxiosError>("user-info", fetchUserInfo, {
    onError: console.error,
  });
};

export const useTileFromCOG = (processingId: string, url?: string) => {
  return useQuery(
    ["tiff-raster-cog", processingId],
    () => {
      if (!url) return;
      return fetchTileURLFromCOG(url);
    },
    {
      enabled: Boolean(url),
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );
};

export const useSchema = (
  options: UseQueryOptions<Schema, AxiosError> = {}
) => {
  const setSchema = useEvent(wizardModel.events.setSchema);

  const query = useQuery<Schema, AxiosError>("schema", () => fetchSchema(), {
    onSuccess: setSchema,
    refetchOnWindowFocus: false,
    ...options,
  });

  return query;
};
